interface ContentFilePayload {
  file: File
}

// These correspond to the variants in the ContentFile model.
export enum ContentFileImageVariant {
  Original = 'original',
  Large = 'large',
  Medium = 'medium',
  Small = 'small',
  Thumbnail = 'thumb',
}

export interface ContentFile extends ApiEntity {
  fileUrl: string
  imageVariantUrls: {
    [key in ContentFileImageVariant]: string
  }
}

export async function createContentFile(attributes: ContentFilePayload) {
  const body = attributes
  // Aaargh.. the `data[attributes]` doesn't include the 'type'.
  const formData = objectToFormData(body, new FormData(), 'data[attributes]')

  const { data } = await $api<JSONAPIResponse<ContentFile>>(
    '/api/v1/cms/content_files',
    {
      method: 'POST',
      body: formData,
    },
  )
  return data.attributes
}
